<template>
  <div>
    <h1>1223131231</h1>
    <h1>1223131231</h1>
    <h1>1223131231</h1>
    <h1>1223131231</h1>
    <h1>1223131231</h1>
    <h1>1223131231</h1>
    <h1>1223131231</h1>
    <h1>1223131231</h1>
    <h1>1223131231</h1>
    <h1>1223131231</h1>
    <h1>1223131231</h1>
    <h1>1223131231</h1>
    <h1>1223131231</h1>
    <Discount></Discount>
  </div>
</template>

<script>
import Discount from '@/components/discount.vue'

export default {
  name: 'Test',
  components: {
    Discount
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {

  }

}
</script>

<style scoped lang='less'>
h1 {
  color: #fff;
}
</style>
